<template>
  <div>
    <el-button type="primary" @click="save">保存</el-button>
    <el-tabs type="border-card" style="margin-top:12px;" value="first">
      <el-tab-pane label="基础信息" name="first">
        <el-form :model="product" label-width="120px" :rules="rules" ref="editProductFormRef">
          <el-form-item label="产品分类" prop="CategoryID">
            <el-select v-model="product.CategoryID" placeholder="请选择">
              <el-option-group v-for="group in category" :key="group.id" :label="group.name">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="产品英文名称" prop="ProductNameEng">
            <el-input v-model="product.ProductNameEng"></el-input>
          </el-form-item>
          <el-form-item label="产品中文名称" prop="ProductNameChn">
            <el-input v-model="product.ProductNameChn"></el-input>
          </el-form-item>
          <el-form-item label="产品英文规格">
            <el-input v-model="product.ProductSizeEng"></el-input>
          </el-form-item>
          <el-form-item label="产品中文规格">
            <el-input v-model="product.ProductSizeChn"></el-input>
          </el-form-item>
          <el-form-item label="产品英文单位">
            <el-input v-model="product.UnitEng"></el-input>
          </el-form-item>
          <el-form-item label="产品中文单位">
            <el-input v-model="product.UnitChn"></el-input>
          </el-form-item>
          <el-form-item label="产品外箱数量">
            <el-input v-model="product.QtyPerCarton"></el-input>
          </el-form-item>
          <el-form-item label="产品内盒数量">
            <el-input v-model="product.QtyPerInner"></el-input>
          </el-form-item>
          <el-form-item label="显示模式:">
            <el-checkbox v-model="product.ViewTags">显示产品说明、技术参数、排气曲线、尺寸图标签</el-checkbox>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="产品说明" name="second">
        <el-form :model="product" label-width="120px">
          <el-form-item label="产品英文说明">
            <el-input type="textarea" v-model="product.BriefDescEng" :rows="8"></el-input>
          </el-form-item>
          <el-form-item label="产品中文说明">
            <el-input type="textarea" v-model="product.BriefDescChn" :rows="8"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="英文技术参数" name="third">
        <el-link target="_blank" href="https://uutool.cn/ueditor/"> 富文本在线编辑器，支持word文档拷贝，前往</el-link>
        <el-input type="textarea" v-model="product.ProductSpecEng" :rows="16"></el-input>
      <!--  <VueUeditorWrap v-model="product.ProductSpecEng" :config="myConfig" /> -->
      </el-tab-pane>
      <el-tab-pane label="中文技术参数" name="fourth">
        <el-link target="_blank" href="https://uutool.cn/ueditor/"> 富文本在线编辑器，支持word文档拷贝，前往</el-link>
        <el-input type="textarea" v-model="product.ProductSpecChn" :rows="16"></el-input>
       <!-- <VueUeditorWrap v-model="product.ProductSpecChn" :config="myConfig" /> -->
      </el-tab-pane>
      <el-tab-pane label="产品图片" name="fifth">
        <el-form :model="product" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品图片">
                <el-upload
                  :file-list="productimg"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedProduct"
                  :on-remove="handleRemoveProduct"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为800*800dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="英文排气曲线">
                <el-upload
                  :file-list="curveengimg"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedCurveeng"
                  :on-remove="handleRemoveCurveeng"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为800*800dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="中文排气曲线">
                <el-upload
                  :file-list="curvechnimg"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedCurvechn"
                  :on-remove="handleRemoveCurvechn"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为800*800dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="英文尺寸图">
                <el-upload
                  :file-list="drawingengimg"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedDrawingeng"
                  :on-remove="handleRemoveDrawingeng"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为800*800dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="中文尺寸图">
                <el-upload
                  :file-list="drawingchnimg"
                  :action="$store.state.baseurl+'/api/file/upload'"
                  list-type="picture"
                  :on-success="uploadedDrawingchn"
                  :on-remove="handleRemoveDrawingchn"
                  :on-error="handleError"
                  :headers="headerObj"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip">只能上传jpg/png/bmp文件，分辨率为800*800dpi且不超过500kb，文件名不能有空格</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
//import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  data() {
    return {
      product: {},
      category: [],
      productimg: [], //产品图片列表
      curveengimg: [], //英文排气曲线图列表
      curvechnimg: [], //中文排气曲线图列表
      dimensionengimg: [], //英文尺寸图列表
      dimensionchnimg: [], //英文尺寸图列表
      drawingengimg: [], //英文尺寸图列表
      drawingchnimg: [], //中文尺寸图列表
      deleteimgs: [], //保存时需要删除的文件列表
      rules: {
        CategoryID: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        ProductNameEng: [
          { required: true, message: '请输入产品英文名称', trigger: 'blur' }
        ],
        ProductNameChn: [
          { required: true, message: '请输入产品中文名称', trigger: 'blur' }
        ]
      },
      headerObj:{
        Authorization:window.localStorage.getItem('token')
      }
    }
  },
  created() {
    this.$http
      .get('/product/getproduct?id=' + this.$route.params.id)
      .then(res => {
        this.product = res.data.data

        //获取产品的图片缩略图
        var p = []
        if (
          this.product.ProductImgUrl != null &&
          this.product.ProductImgUrl != undefined &&
          this.product.ProductImgUrl != ''
        ) {
          this.product.ProductImgUrl.split(' ').forEach(element => {
            var pimg = {}
            pimg.name = element
            pimg.url = this.$store.state.baseurl + '/Images/' + element
            p.push(pimg)
          })
        }
        this.productimg = p

        //获取英文曲线的缩略图
        var ce = []
        if (
          this.product.CurveDrawingEng != null &&
          this.product.CurveDrawingEng != undefined &&
          this.product.CurveDrawingEng != ''
        ) {
          this.product.CurveDrawingEng.split(' ').forEach(element => {
            var pimg = {}
            pimg.name = element
            pimg.url = this.$store.state.baseurl + '/Images/' + element
            ce.push(pimg)
          })
        }
        this.curveengimg = ce

        //获取中文曲线的缩略图
        var ch = []
        if (
          this.product.CurveDrawingChn != null &&
          this.product.CurveDrawingChn != undefined &&
          this.product.CurveDrawingChn != ''
        ) {
          this.product.CurveDrawingChn.split(' ').forEach(element => {
            var pimg = {}
            pimg.name = element
            pimg.url = this.$store.state.baseurl + '/Images/' + element
            ch.push(pimg)
          })
        }
        this.curvechnimg = ch

        //获取英文尺寸图的缩略图
        var de = []
        if (
          this.product.ProductDrawingEng != null &&
          this.product.ProductDrawingEng != undefined &&
          this.product.ProductDrawingEng != ''
        ) {
          this.product.ProductDrawingEng.split(' ').forEach(element => {
            var pimg = {}
            pimg.name = element
            pimg.url = this.$store.state.baseurl + '/Images/' + element
            de.push(pimg)
          })
        }
        this.drawingengimg = de

        //获取中文尺寸图的缩略图
        var dc = []
        if (
          this.product.ProductDrawingChn != null &&
          this.product.ProductDrawingChn != undefined &&
          this.product.ProductDrawingChn != ''
        ) {
          this.product.ProductDrawingChn.split(' ').forEach(element => {
            var pimg = {}
            pimg.name = element
            pimg.url = this.$store.state.baseurl + '/Images/' + element
            dc.push(pimg)
          })
        }
        this.drawingchnimg = dc
      }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })

    this.$http
      .get('/category/getallcat')
      .then(res => (this.category = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
  },
  components: {
    //VueUeditorWrap
  },
  methods: {
    save() {
      this.$refs.editProductFormRef.validate(valid => {
        if (!valid) return
        //把productimg里的文件名转成以空格分隔的字符串保存到product.ProductImgUrl中
        var imgurls = ''
        this.productimg.forEach(element => {
          imgurls += element.name + ' '
        })
        this.product.ProductImgUrl = imgurls.trim()

        //把curveengimg里的文件名转成以空格分隔的字符串保存到product.CurveDrawingEng中
        var curveengurls = ''
        this.curveengimg.forEach(element => {
          curveengurls += element.name + ' '
        })
        this.product.CurveDrawingEng = curveengurls.trim()

        //把curvechnimg里的文件名转成以空格分隔的字符串保存到product.CurveDrawingChn中
        var curvechnurls = ''
        this.curvechnimg.forEach(element => {
          curvechnurls += element.name + ' '
        })
        this.product.CurveDrawingChn = curvechnurls.trim()

        //把drawingengimg里的文件名转成以空格分隔的字符串保存到product.ProductDrawingEng中
        var drawingengurls = ''
        this.drawingengimg.forEach(element => {
          drawingengurls += element.name + ' '
        })
        this.product.ProductDrawingEng = drawingengurls.trim()

        //把drawingchnimg里的文件名转成以空格分隔的字符串保存到product.ProductDrawingChn中
        var drawingchnurls = ''
        this.drawingchnimg.forEach(element => {
          drawingchnurls += element.name + ' '
        })
        this.product.ProductDrawingChn = drawingchnurls.trim()

        this.$http.post('/product/editproduct', this.product).then(res => {
          if (res.data.code != 200) {
            this.$message.error(res.data.errmsg)
          } else {
            this.$message({
              message: '保存成功！',
              type: 'success'
            })
            this.$router.push('/management/productmanagement')
          }
        })
        //删除deleteimgs中的文件
        if (this.deleteimgs.length > 0) {
          this.$http.post('/file/delete', this.deleteimgs).then(res => {
            window.console.log(res)
          }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
        }
      })
    },
    uploadedProduct(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.productimg.push(pimg) 
    },
    uploadedCurveeng(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.curveengimg.push(pimg)
    },
    uploadedCurvechn(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.curvechnimg.push(pimg)
    },
    uploadedDrawingeng(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.drawingengimg.push(pimg)
    },
    uploadedDrawingchn(res) {
      var pimg = {}
      pimg.name = res.data
      pimg.url = this.$store.state.baseurl + '/Images/' + pimg.name
      this.drawingchnimg.push(pimg)
    },
    handleRemoveProduct(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从productimg里删除这个文件
      var index = this.productimg.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.productimg.splice(index, 1)
    },
    handleRemoveCurveeng(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从curveengimg里删除这个文件
      var index = this.curveengimg.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.curveengimg.splice(index, 1)
    },
    handleRemoveCurvechn(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从curveengimg里删除这个文件
      var index = this.curvechnimg.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.curvechnimg.splice(index, 1)
    },
    handleRemoveDrawingeng(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从drawingengimg里删除这个文件
      var index = this.drawingengimg.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.drawingengimg.splice(index, 1)
    },
    handleRemoveDrawingchn(file) {
      //点击删除按钮时，把删除的 文件名保存到deleteimgs里面，当客户点击保存按钮时，从服务器上删除这些文件
      this.deleteimgs.push(file.name)
      //同时要从drawingchnimg里删除这个文件
      var index = this.drawingchnimg.findIndex(item => {
        if (item.name == file) {
          return true
        }
      })
      this.drawingchnimg.splice(index, 1)
    },
    handleError(err){
      window.console.log(err)
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped>
</style>